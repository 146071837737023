import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.merchantAccountId = ''
      this.orderType = ''
      this.orderFrom = ''
      this.payType = ''
      this.sportsProjectCode = ''
      this.range = []
      this.entityBaseInfoId = ''
      this.searchType = 'platform'
    }
  }
}
export default searchFrom
